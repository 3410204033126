import { AuthenticationModule } from '@aa/authentication/authentication.module';
import { authorizationInterceptor } from '@aa/authentication/authorization-interceptor';
import { MapModule } from '@aa/map/map.module';
import { FullnameOrUsernamePipe } from '@aa/pipes/fullname-or-username.pipe';
import { DateTimePipe, ToDateTimePipe } from '@aa/pipes/to-date.pipe';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { HttpInterceptorFn, provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from "@environments/environment";
import { AASvgIconRegistryService } from '@generated/aa-svg-icon-registry.service';
import { colors } from '@generated/tailwind-colors';
import { PointWithErrorBar, ScatterWithErrorBarsController } from 'chartjs-chart-error-bars';
import Annotation from 'chartjs-plugin-annotation';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { ErrorComponent } from './error/error.component';
import { DashboardsComponent } from './landing/dashboards/dashboards.component';
import { HomeComponent } from './landing/home/home.component';
import { LandingComponent } from './landing/landing.component';
import { SvgIconComponent } from 'angular-svg-icon';

export const COLORS = colors;
export const CHART_GRID_COLOR = COLORS['gray-100'];
export const CHART_FONT_COLOR = COLORS['gray-400'];
export const CHART_FONT = {
  family: 'Cabin',
  size: 20,
  weight: 500,
  lineHeight: 1.25,
};
export const CHART_SCALES_FONT = { ...CHART_FONT, lineHeight: 1.4 };

const API_URL = environment.apiUrl;
const API_CLIENT_ID = environment.apiClientId;
const API_CLIENT_SECRET = environment.apiClientSecret;

export const acceptVersionInterceptor: HttpInterceptorFn = (req, next) => {
  const cloned = req.clone({ setHeaders: { Accept: 'application/json; version=v18;' } });
  return next(cloned);
}

export const apiUrlInterceptor: HttpInterceptorFn = (req, next) => {
  let request = req;
  if (request.url.charAt(0) === '/' && !request.url.startsWith('/assets')) {
    request = req.clone({ url: API_URL + req.url });
  }
  return next(request);
}

export const appFlagsInterceptor: HttpInterceptorFn = (req, next) => {
  const app = inject(AppService);

  let params = req.params;

  const idsVisible = app.idsVisible;
  if (idsVisible) { params = params.set('ids_visible', 1); }

  const profile = app.logDb;
  if (profile) { params = params.set('_log_db', 1); }

  return next(req.clone({ params }));
};

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    DashboardsComponent,
    ErrorComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    AuthenticationModule.forRoot(API_CLIENT_ID, API_CLIENT_SECRET),
    MapModule,
    CdkTableModule,
    CdkMenuModule,
    FullnameOrUsernamePipe,
    DateTimePipe,
    ToDateTimePipe,
    HomeComponent,
    SvgIconComponent,
  ],
  providers: [
    AppService,
    provideHttpClient(withInterceptors([apiUrlInterceptor, acceptVersionInterceptor, appFlagsInterceptor, authorizationInterceptor])),
    provideCharts(withDefaultRegisterables([ScatterWithErrorBarsController, PointWithErrorBar, Annotation])),
    AASvgIconRegistryService.provide(),
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
